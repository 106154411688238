import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

export const formatDate = (date: Date) => {
  if (!date) {
    return null
  }
  return new Date(date).toLocaleDateString('en-us', {day: 'numeric', year: 'numeric', month: 'short'})
}

export const formatTimeAgo = (date: Date) => {
  const timeAgo = new TimeAgo('en-US')
  if (date) {
    return timeAgo.format(new Date(date))
  }
  return '-'
}

export const formatCurrency = (number: number) => {
  return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(number)
}

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat('en-US').format(number)
}

export const formatPercentage = (number: number) => {
  return new Intl.NumberFormat('en-US', {style: 'percent', maximumFractionDigits: 2}).format(number / 100)
}

export const formatBigNumber = (number: number) => {
  if (!number) {
    return 0
  }
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1
  }).format(number)
}
